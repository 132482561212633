.home-page .scroll-down-btn {
	position: absolute;
	bottom: 30px;
	z-index: 2;
	left: 50%;
	transform: translateX(-50%);
	z-index: 3;
}

@keyframes rippleScrollDownBtn {
	from {
		opacity: .8;
		transform: scale(1);
	}

	50% {
		opacity: .4;
	}

	to {
		opacity: 0;
		transform: scale(1.5);
	}
}

.home-page .scroll-down-btn::before {
	content: '';
	background: #ffffff;
	position: absolute;
	left: 0; right: 0;
	top: 0; bottom: 0;
	z-index: 1;
	border-radius: 50%;
	animation: rippleScrollDownBtn 2s infinite;
}

.home-page .scroll-down-btn > div {
	background-color: #ffffff;
	position: relative;
	z-index: 5;
	width: 60px;
	height: 60px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: #000000;
}

.home-page .parallax-banner {
	height: 100vh;
	background-color: #dddddd;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
}

.home-page .parallax-banner > div {
	height: 100vh;
	width: 100%;
}

.home-page .parallax-banner .background {
	transition: all .3s; /* sepia(.2) saturate(1.2)   brightness(.8) */
	width: 110%;
	height: 110%;
	background-size: cover;
	position: relative;
	top: -5%;
	left: -5%;
}

.home-page .parallax-banner .title {
	white-space: nowrap;
	position: absolute;
	width: 100%;
	top: 30%;
	font-size: 60px;
	color: #fff;
	text-shadow: #000 1px 0 10px;
}

.home-page .parallax-banner .subtitle {
	white-space: nowrap;
	position: absolute;
	width: 100%;
	top: 38%;
	font-size: 30px;
	color: #fff;
	text-shadow: #000 1px 0 10px;
}

.plane-parallax {
	background-size: cover;
	position: relative;
}

.home-page #plane-3 {
	width: 120%;
	height: 120%;
	background-position: -550px 10%;
	top: 50px; /* sepia(.15) */
	left: -5%;
}

.home-page .last-images {
	background-color: #ffffff;
	position: absolute;
	bottom: 20px;
	right: 20px;
	z-index: 5;
	overflow: hidden;
	display: flex;
	clip-path: polygon(34px 0, 0 100%, calc(100% - 34px) 100%, 100% 0);
	padding: 10px 15px 10px 33px;
	justify-content: flex-end;
}

.home-page .last-images > div {
	width: 266px;
	height: 150px;
	margin-left: -20px;
	clip-path: polygon(30px 0, 0 100%, calc(100% - 30px) 100%, 100% 0);
}

.home-page .last-images img {
	height: 100%;
}

.home-page .center-square {
	pointer-events: all !important;
	z-index: 5;
}

.home-page .center-square > div {
	width: 700px;
	height: 300px;
	background-color: #ffffff;
	position: absolute;
	top: 65%;
	left: 35%;
	transform: translate(-50%);
	border-radius: 10px;
	overflow: hidden;
}

.home-page .slides-container {
	width: 200%;
	display: flex;
}

.home-page .slide {
	transition: transform .3s;
	transform: translate(0%);
	display: flex;
	flex-direction: column;
}

.home-page .center-square > div:hover .slide {
	transform: translate(-100%);
}

.home-page .slide-title {
	font-size: 20px;
	text-align: center;
	padding: 10px 15px;
	font-weight: 700;
}

.home-page .slide-content {
	flex: 1;
	padding: 10px 15px;
	text-align: justify;
}

.home-page h1 {
	margin-top: 0;
	margin-bottom: 50px;
	color: inherit;
}

.home-page p {
	margin-bottom: 0;
}

.home-page .container {
	padding-bottom: 100px;
	padding-top: 100px;
}

#what-is-bfr {
	background-color: #012d66; /*  011e44 */
	color: #ffffff;
}

#bfr-stats .stats-list {
	display: flex;
	margin-top: 60px;
	text-align: center;
}

#bfr-stats .stats-list .stat-item {
	border-right: 1px solid rgba(255, 255, 255, .3);
	flex: 1;
}

#bfr-stats .stats-list .stat-item:last-child {
	border: 0;
}

#bfr-stats .stats-list .stat-stat {
	font-size: 50px;
	font-weight: 700;
}

#bfr-stats .stats-list .stat-label {
	font-size: 20px;
	font-weight: 100;
}

#all-section-banner {
	height: 300px;
}

#all-section-banner .background {
	height: 100%;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-image: url("https://www.cloud3dprint.com/assets/images/img/1920x1080/img19.jpg");
}

#all-section {
	display: grid;
	grid-template-areas: "infos-server gallery";
}

#all-section .container {
	padding-top: 50px;
	padding-bottom: 50px;
}

#gallery-section {
	grid-area: gallery;
	padding-left: 150px;
	padding-right: 150px;
	background: #ececec;
}

#gallery-section .section-text {
	font-size: 20px;
	margin-bottom: 40px;
}

#gallery-section .gallery-content {
	padding-bottom: 20px;
	gap: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 200px 200px;
}

#gallery-section .gallery-content > a:first-child {
	grid-column: span 2;
}

#gallery-section .gallery-content > a {
	border-radius: 10px;
	cursor: pointer;
	height: 100%;
	overflow: hidden;
}

#gallery-section .gallery-content .background {
	transition: all .3s;
	height: 100%;
	background-size: cover;
	background-position: center;
}

#gallery-section .gallery-content .background:hover {
	transform: scale(1.1);
}

#ranking-section {
	grid-area: ranking;
	background-color: #dddddd;
}

#ranking-section .content {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 20px;
}

#ranking-section .content > div {
	flex: 1;
}

#ranking-section .ranking-item {
	padding-right: 10px;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

@media all and (max-width: 500px) {
	#ranking-section {
		padding-left: 0;
		padding-right: 0;
	}

	#ranking-section .A2A-ranking .player-kills, #ranking-section .player-looses {
		display: none;
	}
}

#infos-section {
	grid-area: infos-server;
}

#infos-section .content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 100px 1fr;
	align-items: center;
}

#infos-section .content .top {
	grid-column: span 2;
	display: flex;
	justify-content: space-between;
}

#see-soon-section {
	text-align: center;
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	font-size: 30px;
	color: #ffffff;
	background-color: #022d64;
}

#join-us {
	font-size: 30px;
	font-weight: 700;
}

#see-soon-section .social-media {
	display: flex;
	justify-content: center;
	gap: 50px;
	margin-bottom: 20px;
}

.social-media-item {
	position: relative;
	padding-top: 20px;
	cursor: pointer;
}

.social-media-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 100px;
}

.social-media-icon img {
	height: 100%;
	height: 40px;
}

.social-media-label {
	font-size: 16px;
	position: absolute;
	top: 75%;
	left: 50%;
	transform: translate(-50%);
	white-space: nowrap;
	text-align: center;
	transition: opacity .3s;
	opacity: 0;
}

.social-media-item:hover .social-media-label {
	opacity: 1;
}

@media all and (max-width: 1500px) and (min-width: 1300px) {
	#gallery-section {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media all and (max-width: 1300px) {
	#all-section {
		grid-template-areas: "ranking"
							 "gallery"
							 "infos-server";
	}
}

@media all and (max-width: 800px) {
	#gallery-section {
		padding-left: 20px;
		padding-right: 20px;
	}

	#bfr-stats .stats-list .stat-stat {
		font-size: 40px;
	}

	#bfr-stats .stats-list .stat-label {
		font-size: 15px;
	}

	.home-page .parallax-banner .title {
		font-size: 40px;
		top: 32%;
	}

	.home-page .parallax-banner .subtitle {
		font-size: 20px;
		top: 37%;
	}

}

@media all and (max-width: 500px) {
	.home-page .parallax-banner .title {
		font-size: 30px;
		top: 34%;
	}

	.home-page .parallax-banner .subtitle {
		font-size: 15px;
		top: 38%;
	}

}
