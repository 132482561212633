.form-group textarea {
	outline: 0;
	max-width: 100%;
	border: 0;
	background-color: transparent;
	font-family: inherit;
	font-size: inherit;
	border: 3px solid #0e77ff;
	border-radius: 5px;
	padding: 10px 15px;
    resize: vertical;
}