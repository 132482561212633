.logs-container {
	border-radius: 5px;
	background-color: #eee;
	padding: 10px 15px;
	border: 1px solid #ccc;
	margin-bottom: 50px;
	font-size: 16px;
	overflow: auto;
}

.logs-container > div {
	white-space: break-spaces;
	white-space: pre-wrap;
}