.login-page {
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.login-page .section-title {
	margin-top: 150px;
}

.login-page .login-form {
	width: 400px;
}