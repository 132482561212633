.form-group {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 20px 0;
	flex: 1;
	max-width: 100%;
}

.form-group input {
	outline: 0;
	max-width: 100%;
	border: 0;
	background-color: transparent;
	font-family: inherit;
	font-size: inherit;
	border: 3px solid #0e77ff;
	border-radius: 5px;
	padding: 10px 15px;
	flex: 1;
}

.form-group label {
	transform-origin: left center;
	transition: transform .1s;
	background-color: #ffffff;
	font-size: 16px;
	position: absolute;
	left: 15px; top: -10px;
	height: 20px;
	padding: 0 5px;
	transform: translateY(24px) scale(1);
	cursor: text;
}

.form-group.is-focused label, .form-group.has-content label {
	transform: translateY(0) scale(.9);
}

.form-group .help {
	text-align: center;
	font-size: 14px;
	margin-top: 5px;
}