.infos-server-title, .infos-server-page .section-title {
	background-color: #022d64;
	margin: 0;
	color: #ffffff;
	height: 80px;
	font-size: 35px;
	line-height: 80px;
	text-align: center;
}

.infos-server-page section {
	padding-top: 60px;
	margin-top: -60px;
}

.infos-server-content {
	padding-top: 40px;
	padding-bottom: 40px;
	color: #222222;
	font-size: 18px;
	line-height: 28px;
}

.author {
	text-align: right;
	font-size: 14px;
}

@media all and (max-width: 1250px) {
	.infos-server-page .frequentations-graph {
		display: none;
	}
}

.infos-server-1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 100px 1fr;
	align-items: center;
}

.infos-server-1 .top {
	grid-column: span 2;
	display: flex;
	justify-content: space-between;
}

.phraseology-title {
	font-size: 30px;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 30px;
}

.download-file {
	float: right;
}

#phraseology ul {
	margin-bottom: 0;
}

#phraseology ul > li {
	margin-bottom: 20px;
}

#phraseology ul ul {
	margin-bottom: 0;
}

#phraseology ul ul > li {
	margin-bottom: 0;
}

#phraseology .phraseology-words li {
	margin-bottom: 0;
}