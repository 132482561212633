.tournament-bracket {
	text-align: center;
	max-width: 1400px;
}

.tournament-bracket .round-list {
	display: grid;
	grid-auto-flow: column;
}

.tournament-bracket .round-list .round {
    position: relative;
	min-width: 240px;
}

.tournament-bracket .round-title {
	font-weight: 700;
	color: #0e77ff;
	background-color: #ececec;
	margin: 0 2px;
	padding: 5px 0;
}

.tournament-bracket .match-list {
	display: flex;
	flex-direction: column;
    padding-top: calc(var(--padding-top) / 2);
}

.tournament-bracket .match {
	position: relative;
	height: 54px;
	color: #000000;
	border-radius: 5px;
	margin: 2px 10px;
}

.tournament-bracket .match.hidden {
	visibility: hidden;
}

.tournament-bracket .match .players > div {
    display: flex;
    gap: 20px;
	transition: all .2s;
	height: 25px;
	line-height: 25px;
	background-color: #ececec; /* #141726 */
	border-radius: 0 0 5px 5px;
	font-size: 14px;
}

.tournament-bracket .match .players > div.has-player {
	cursor: pointer;
}

.tournament-bracket .match .players > div.has-player:hover {
	background-color: #dddddd; /* 1e2238  */
}

.tournament-bracket .match .players > div:first-child {
	margin-bottom: 2px;
	border-radius: 5px 5px 0 0;
}

.tournament-bracket .match .players > div.is-winner .score {
    background-color: #00ff55;
}

.tournament-bracket .match.is-final .players > div.is-winner,
.tournament-bracket .match.is-final .players > div.is-winner .score {
    background-color: #f8e800;
}

.tournament-bracket .match.is-final .players > div.is-looser,
.tournament-bracket .match.is-final .players > div.is-looser .score {
    background-color: #7e838f;
}

.tournament-bracket .match.is-small-final .players > div.is-winner,
.tournament-bracket .match.is-small-final .players > div.is-winner .score {
    background-color: #a95834;
}

.tournament-bracket .match.is-small-final .players > div.is-winner,
.tournament-bracket .match.is-final .players > div.is-looser {
    color: #fff;
}

.players .player-name {
    flex: 1;
}

.players .score {
    background-color: #ddd;
    width: 20px;
}

.players .player1 .score {
	border-radius: 0 5px 0 0;
}

.players .player2 .score {
	border-radius: 0 0 5px 0;
}

.tournament-bracket .match-list .match .match-before, .match-list .match .match-after {
	position: absolute;
	top: 50%;
	transform: translateY(-2.5px);
}

.tournament-bracket .round-list .round:first-child .match-before {
	display: none;
}

.tournament-bracket .round-list .round:last-child .match-after {
	display: none;
}

.tournament-bracket .match-list .match .match-before {
	background-color: #ececec; /* 181d30 */
	left: -8px;
	width: 8px;
	height: 2px;
}

.tournament-bracket .match-list .match .match-after {
	right: -15px;
	width: 15px;
	height: 100px;
	border-top: 2px solid #ececec; /* 181d30 */
	border-right: 2px solid #ececec; /* 181d30 */
}

.tournament-bracket .match-list .match:nth-child(2n) .match-after {
	top: unset;
	bottom: 50%;
	border-top: none;
	border-bottom: 2px solid #ececec; /* 181d30 */
	transform: translateY(0px);
}

.match-admin-controls {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}

.match-score-input-group {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.match-score-input-group > div {
    min-width: 100px;
}

.match-winner-input-group {
    font-size: 20px;
    justify-content: center;
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.match-winner-input-group > div {
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.match-winner-input-group > div.selected {
    background-color: #0e77ff;
    color: #fff;
}

.tournament-bracket .is-final {
    margin-top: calc(var(--match-height) / 2 * -1);
}

.tournament-bracket .is-small-final {
    position: absolute;
    left: 0; right: 0;
    top: calc(var(--padding-top) / 2);
    margin-top: calc(var(--match-height) / 2 + 40px);
}

.tournament-admin-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.tournament-bracket .match {
    border: 1px solid transparent;
}

.tournament-bracket .match.is-started {
    border: 1px solid #a0a02a;
}