.selected-player-context-menu {
	position: absolute;
	left: 756px;
	top: 271px;
	display: none;
	background-color: #ffffff;
	border-radius: 0 15px 15px 15px;
	overflow: hidden;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	width: 200px;
	z-index: 2;
}

.selected-player-context-menu.is-visible {
	display: block;
}

.selected-player-context-menu .list-item {
	border-bottom: 1px solid rgba(0,0,0,0.15);
}