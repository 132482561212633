.tournaments-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.tournament {
    text-decoration: none;
    color: inherit;
    border: 1px solid #0e77ff;
    border-radius: 5px;
    padding: 10px 0;
    display: flex;
    font-size: 16px;
}

.tournament.tournament-pending {
    border-color: #0d5c0d;
}

.tournament.tournament-started {
    border-color: #941818;
}

.tournament.tournament-ended {
    border-color: #a1a12a;
}

.tournament-form {
    width: 400px;
}

.tournament>div {
    display: flex;
    align-items: center;
}

.tournament .tournament-icon {
    width: 50px;
    justify-content: center;
}

.tournament .tournament-name {
    flex: 1;
    font-size: 20px;
}

.tournament .tournament-controls {
    text-decoration: underline;
    padding-right: 20px;
}

.tournament-register-page .container,
.tournaments-create-page .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tournament-admin-controls {
    display: flex;
    justify-content: space-between;
}

.tournament-players-list {
    display: flex;
    flex-direction: column;
}

.tournament-player-item {
    max-width: 500px;
    transition: .2s;
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 20px;
    padding: 10px;
    border-radius: 5px;
    margin: 5px auto;
}

.tournament-player-item:hover {
    background-color: #f7f7f7;
}

.tournament-player-item>div:first-child {
    flex: 1;
    margin-right: 25px;
}

.tournament-player-item.validated {
    background-color: #b7f1b7;
}

.tournament-player-item input {
    display: inline-block;
    border: 0;
    outline: 0;
    background-color: transparent;
    -moz-appearance: textfield;
    text-align: right;
    color: inherit;
    font-size: inherit;
}

.tournament-player-item input::-webkit-outer-spin-button,
.tournament-player-item input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.tournament-admin-controls-btns {
    display: flex;
    gap: 20px;
    height: min-content;
}

.tournament-nav-container {
    display: flex;
    justify-content: center;
}

.tournament-nav-item {
    transition: color .2s;
    min-width: 150px;
    text-align: center;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
}

.tournament-nav-item.selected,
.tournament-nav-item:hover {
    color: #0e77ff;
}

.tournament-nav-item.selected {
    border-bottom: 2px solid #0e77ff;
}

.tournament-description {
    white-space: pre-wrap;
    text-align: left;
    font-size: 20px;
    margin: 15px 0;
}

.tournament-view-admin-controls .controls-btn-group {
    display: flex;
    gap: 20px;
}

.tournament-video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

/* .tournament-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    border: 0;
} */

.maps-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 20px;
}

.maps-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.maps-list>div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.register-form {
    margin-bottom: 30px;
}

.selected-player-popup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}