.popup-overlay {
	position: fixed;
	top: 0; bottom: 0;
	left: 0; right: 0;
	background-color: rgba(0, 0, 0, .4);
	z-index: 10;
}

.popup {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 10%;
	left: 50%;
	transform: translateX(-50%);
	height: auto;
	min-height: 200px;
	width: 600px;
	background-color: #ffffff;
	z-index: 11;
	border-radius: 5px;
}

.popup .popup-header {
	border-bottom: 1px solid rgba(0, 0, 0, .15);
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	color: #0e77ff;
}

.popup .popup-body {
	flex: 1;
	padding: 10px 20px;
}

.popup .popup-body > div {
	height: 100%;
}

/*
.popup-enter {
	opacity: 0;
}
.popup-enter-active {
	opacity: 1;
	transition: opacity 200ms;
}
.popup-exit {
	opacity: 1;
}
.popup-exit-active {
	opacity: 0;
	transition: opacity 200ms;
}
*/
.popup-enter .popup {
	opacity: 0;
	top: 10%;
}

.popup-enter-active .popup {
	opacity: 1;
	top: 30%;
	transition: top 200ms, opacity .3s;
}

.popup-enter-done .popup {
	opacity: 1;
	top: 30%;
}

.popup-exit .popup {
	opacity: 1;
	top: 30%;
}

.popup-exit-active .popup {
	opacity: 0;
	top: 10%;
	transition: top 200ms, opacity .3s;
}

.popup-enter .popup-overlay {
	opacity: 0;
}

.popup-enter-active .popup-overlay {
	opacity: 1;
	transition: opacity 200ms;
}

.popup-exit .popup-overlay {
	opacity: 1;
}

.popup-exit-active .popup-overlay {
	opacity: 0;
	transition: opacity 200ms;
}