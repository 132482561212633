/*
* DARK THEME
*/

.dark-theme {
    background-color: #181a1b !important;
}

.dark-theme a {
    color: #3391e5;
}

.dark-theme header.header-transparent {
    background-color: rgba(0, 0, 0, 0.5);
}

.dark-theme header.header {
    background-color: rgb(24, 26, 27);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 1px;
    color: rgb(211, 207, 201);
}

.dark-theme header.header-transparent {
    color: rgb(232, 230, 227);
    background-color: transparent;
    box-shadow: none;
}

.dark-theme header.header>a, .dark-theme header.header nav.nav>a, .dark-theme header.header nav.nav .dropdown-title, .dark-theme header.header .dropdown-items>div>* {
    text-decoration-color: currentcolor;
    color: inherit;
}

.dark-theme .dropdown-items>div>*:first-child::after {
    border-bottom: 10px solid #181a1b;
}

.dark-theme .dropdown-items>div>*:first-child:hover::after {
    border-bottom: 10px solid #232627;
}

.dark-theme header.header nav.nav>.header-hover:hover, .dark-theme header.header .dropdown-items>div>*:hover {
    background-color: #232627 !important;
}

.dark-theme header.header-transparent nav.nav>.header-hover:hover {
    background-color: transparent !important;
}

.dark-theme header.header-transparent nav.nav> :hover {
    background-color: transparent;
}

.dark-theme header.header-transparent>a, .dark-theme header.header-transparent nav.nav>* {
    color: rgb(232, 230, 227);
}

.dark-theme header.header .logo {
    color: rgb(35, 151, 255);
}

.dark-theme header.header-transparent .logo {
    color: rgb(232, 230, 227);
}

.dark-theme header.header .logo img {
    background-color: rgb(0, 0, 0);
}

.dark-theme header.header .dropdown-items {
    color: rgb(211, 207, 201);
}

.dark-theme header.header .dropdown-items>div {
    background-color: rgb(24, 26, 27);
}

.dark-theme header.header .dropdown-items a {
    text-decoration-color: currentcolor;
    border-bottom-color: rgba(140, 130, 115, 0.1);
    color: inherit;
}

.dark-theme header.header .dropdown-items a:last-child {
    border-bottom-color: currentcolor;
}

.dark-theme header.header .hamburger-icon span {
    background-color: rgb(0, 0, 0);
}

.dark-theme header.header-transparent .hamburger-icon span {
    background-color: rgb(24, 26, 27) !important;
}

.dark-theme header.header .menu-overlay {
    background-color: rgb(0, 0, 0);
}

@media (max-width: 1000px) {
    .dark-theme header.is-opened .logo {
        color: rgb(35, 151, 255);
    }
    .dark-theme header.header nav.nav {
        background-color: rgb(24, 26, 27);
        color: rgb(232, 230, 227);
    }
    .dark-theme header.header nav.nav>* {
        border-bottom-color: rgba(140, 130, 115, 0.15);
    }
    .dark-theme header.header nav.nav> :first-child {
        border-top-color: rgba(140, 130, 115, 0.15);
    }
    .dark-theme header.header-transparent>a, .dark-theme header.header-transparent nav.nav>* {
        color: rgb(232, 230, 227);
    }
}

.dark-theme .container, .dark-theme .black-color {
    color: #e8e6e3;
}

.dark-theme .card {
    background-color: #181a1b;
}

.dark-theme .card .card-title {
    color: rgb(189, 183, 175);
}

.dark-theme .card, .dark-theme .card-title {
    border-color: rgba(140, 130, 115, 0.15);
}

.dark-theme .list .list-header {
    color: rgb(189, 183, 175);
}

.dark-theme .list .list-item {
    color: #e8e6e3;
}

.dark-theme .list .list-item:hover {
    background-color: rgb(35, 38, 40);
}

.dark-theme #ranking-section {
    background-color: #2b2f31;
}

.dark-theme #gallery-section {
    background-color: #232627;
}

.dark-theme #infos-section {
    background-color: #181a1b;
}

.dark-theme .ranking-list .table {
    background-color: rgb(0, 0, 0);
}

.dark-theme .ranking-list .table::before {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(203, 169, 0, 0.25) 40%, rgba(203, 169, 0, 0.25) 60%, rgba(0, 0, 0, 0) 100%);
}

.dark-theme .ranking-list .table .ranking-place {
    color: rgb(157, 148, 136);
}

.dark-theme .ranking-list .table .gold .ranking-place, .dark-theme .ranking-list .table .silver .ranking-place, .dark-theme .ranking-list .table .bronze .ranking-place {
    color: rgb(232, 230, 227);
}

.dark-theme .ranking-list .table .gold .ranking-place::before {
    background-color: rgb(203, 169, 0);
}

.dark-theme .ranking-list .table .silver .ranking-place::before {
    background-color: rgb(53, 57, 59);
}

.dark-theme .ranking-list .table .bronze .ranking-place::before {
    background-color: rgb(126, 66, 54);
}

.dark-theme .ranking-item {
    text-decoration-color: currentcolor;
    color: inherit;
    background-color: rgb(24, 26, 27);
}

.dark-theme .ranking-item:hover {
    background-color: rgb(32, 35, 37);
}

.dark-theme .ranking-header:hover {
    background-color: rgb(24, 26, 27);
}

.dark-theme .ranking-item .ranking-place {
    color: rgb(232, 230, 227);
    background-color: rgb(0, 85, 196);
}

.dark-theme .ranking-item.gold .ranking-place {
    background-color: rgb(203, 169, 0);
}

.dark-theme .ranking-item.silver .ranking-place {
    background-color: rgb(53, 57, 59);
}

.dark-theme .ranking-item.bronze .ranking-place {
    background-color: rgb(126, 66, 54);
}

.dark-theme .ranking-header .ranking-place {
    background-color: transparent;
}

.dark-theme .ranking-list .search-player input {
    outline-color: currentcolor;
    border-color: currentcolor;
    background-color: rgb(24, 26, 27);
}

.dark-theme .ranking-list .see-more {
    text-decoration-color: currentcolor;
}

.dark-theme .infos-server-title, .dark-theme .infos-server-page .section-title {
    background-color: rgb(2, 36, 80);
    color: rgb(232, 230, 227);
}

.dark-theme .infos-server-content {
    color: rgb(211, 207, 201);
}

.dark-theme .social-media-item {
    color: inherit;
}

.dark-theme #what-is-bfr a {
    color: inherit;
}

.dark-theme .search-bar {
    color: inherit;
}

.dark-theme .search-bar-form input {
    outline-color: currentcolor;
    border-color: currentcolor;
    background-color: rgb(24, 26, 27);
    color: #e8e6e3;
}

.dark-theme .search-bar .search-bar-suggestions>* {
    background-color: rgb(24, 26, 27);
    text-decoration-color: currentcolor;
    color: #e8e6e3;
}

.dark-theme .search-bar .search-bar-suggestions> :hover {
    background-color: rgb(38, 41, 43);
}

.dark-theme ::placeholder {
    color: #b2aba1;
    opacity: 0.5 !important;
}

.dark-theme .background-grey {
    background-color: rgb(34, 37, 39);
}

.dark-theme .section-title .pre-icon, .dark-theme .section-title .post-icon {
    color: inherit;
}

.dark-theme .section-title .pre-icon:hover, .dark-theme .section-title .post-icon:hover {
    background-color: rgb(43, 47, 49);
}

.dark-theme .btn {
    color: #e8e6e3;
}

.dark-theme .table {
    border-color: rgb(48, 52, 54);
    color: #e8e6e3;
}

.dark-theme .table thead th {
    background-color: rgb(0, 85, 196);
    color: rgb(232, 230, 227);
}

.dark-theme .table tbody tr {
    background-color: rgb(24, 26, 27);
}

.dark-theme .table tbody tr:nth-child(2n) {
    background-color: rgb(32, 35, 37);
}

.dark-theme .table tbody tr:hover {
    background-color: rgb(35, 38, 40);
}

.dark-theme .calendar-container {
    background-color: rgb(24, 26, 27);
}

.dark-theme .calendar-header>div {
    color: rgb(166, 159, 147);
    border-color: rgb(53, 58, 60);
}

.dark-theme .calendar-day {
    border-color: rgb(53, 58, 60);
}

.dark-theme .calendar-day .day-number {
    color: rgb(166, 159, 147);
}

.dark-theme .calendar-day.not-current-month .day-number {
    color: rgb(180, 173, 163);
}

.dark-theme .calendar-container .event-item .event-box {
    background-color: rgb(0, 118, 186);
    color: rgb(232, 230, 227);
}

.dark-theme .calendar-container .event-item.gradient-1 .event-box {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(115deg, rgb(144, 114, 5) 0px, rgb(185, 71, 7) 120px);
}

.dark-theme .calendar-container .event-item.gradient-2 .event-box {
    background-color: rgb(0, 118, 186);
    background-image: linear-gradient(160deg, rgb(0, 118, 186) 0px, rgb(42, 113, 107) 100px);
    color: rgb(232, 230, 227);
}

.dark-theme .calendar-container .event-item.gradient-3 .event-box {
    background-color: rgb(38, 57, 155);
    background-image: linear-gradient(43deg, rgb(45, 62, 148), rgb(144, 13, 135) 100px);
    color: rgb(232, 230, 227);
}

.dark-theme .calendar-container .event-item.gradient-4 .event-box {
    background-color: rgb(2, 150, 184);
    background-image: linear-gradient(19deg, rgb(2, 150, 184) 0px, rgb(124, 0, 184) 100px);
    color: rgb(232, 230, 227);
}

.dark-theme .calendar-container .event-item.gradient-5 .event-box {
    background-color: rgb(169, 146, 0);
    background-image: linear-gradient(147deg, rgb(169, 146, 0) 0%, rgb(182, 0, 0) 74%);
    color: rgb(232, 230, 227);
}

.dark-theme .calendar-container .event-item.gradient-6 .event-box {
    background-color: rgb(145, 114, 4);
    background-image: linear-gradient(45deg, rgb(145, 114, 4) 0px, rgb(150, 0, 105) 100px);
    color: rgb(232, 230, 227);
}

.dark-theme .status-page {
    color: #e8e6e3;
}

.dark-theme .status-page .status-container {
    border-color: rgb(67, 73, 76);
    background-color: rgb(43, 47, 49);
}

.dark-theme .status-page .status-container.online {
    border-color: rgb(6, 173, 6);
    background-color: rgb(33, 133, 0);
}

.dark-theme .status-page .status-container.offline {
    border-color: rgb(168, 13, 13);
    background-color: rgb(155, 0, 0);
}

.dark-theme .form-group label {
    background-color: #181a1b;
}

.dark-theme .form-group input, .dark-theme .form-group textarea {
    color: #e8e6e3;
}

.dark-theme .chat .chat-message-form button {
    color: #e8e6e3;
}

.dark-theme .tournament-bracket .round-title {
    background-color: #2e3245;
    color: #e8e6e3;
}

.dark-theme .tournament-bracket .match .players>div {
    background-color: #2e3245;
    color: #e8e6e3;
}

.dark-theme .tournament-bracket .match .score {
    background-color: #383c4d;
    color: #e8e6e3;
}

.dark-theme .tournament-bracket .match .players>div.is-winner .score {
    background-color: #6f5ffe;
}

.dark-theme .tournament-bracket .match .players>div.has-player:hover {
    background-color: #474c62;
}

.dark-theme .tournament-bracket .match-list .match .match-before {
    background-color: #252a3a;
}

.dark-theme .tournament-bracket .match-list .match .match-after {
    border-color: #252a3a !important;
}

.dark-theme .icon {
    color: #e8e6e3;
}

.dark-theme .icon:hover {
    background-color: #252a3a;
}

.dark-theme .tournament-player-item:hover {
    background-color: #252a3a;
}

.dark-theme .tournament-player-item.validated {
    background-color: #78af78;
    color: #000;
}

.dark-theme .popup {
    background-color: #252a3a;
    color: #e8e6e3;
}

.dark-theme .popup .popup-header {
    border-color: #000;
}

.dark-theme .tournament .tournament-icon {
    color: #f7f7f7;
}

.dark-theme .tournament .tournament-name {
    color: #f7f7f7;
}

.dark-theme .tournament .tournament-controls > * {
    color: #f7f7f7;
}