.search-bar {
	position: absolute;
	top: 30%;
	left: 50%; right: 50%;
	transform: translateX(-50%);
	width: 600px;
	box-sizing: border-box;
	padding: 0 20px;
}

@media all and (max-width: 600px) {
	.search-bar {
		width: 100%;
	}
}

.search-bar > div {
	width: 100%;
}

.search-bar-form input {
	outline: 0;
	border: 0;
	background-color: rgba(255, 255, 255, 1);
	padding: 10px 15px;
	font-size: 18px;
	width: 100%;
}

.search-bar .search-bar-suggestions > * {
	display: block;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 1);
	padding: 10px 15px;
	text-decoration: none;
	color: inherit;
}

.search-bar .search-bar-suggestions > *:hover {
	background-color: rgba(230, 230, 230, 1);
}