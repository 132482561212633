.sidebar {
	min-height: 100vh;
	min-width: 300px;
	max-width: 300px;
	background-color: #2a2d3e; /* #0627df    001279      */
	color: #ffffff;
}

.sidebar .sidebar-title {
	font-size: 22px;
	padding: 0 15px;
	height: 60px;
	line-height: 60px;
}

.sidebar .sidebar-links {
	display: flex;
	flex-direction: column;
}

.sidebar .sidebar-links > a, .sidebar .sidebar-links .sub-menu-title {
	color: inherit;
	text-decoration: none;
	font-size: 18px;
	cursor: pointer;
	padding: 10px 15px;
}

.sidebar .sidebar-links a:hover, .sidebar .sidebar-links .sub-menu-title:hover, .sidebar .sidebar-links .sub-menu-item:hover {
	background-color: rgba(255, 255, 255, .2);
}

.sidebar .sidebar-links .sub-menu-content {
	display: flex;
	flex-direction: column;
}

.sidebar .sidebar-links .sub-menu-item {
	color: inherit;
	text-decoration: none;
	font-size: 16px;
	cursor: pointer;
	padding: 10px 15px 10px 35px;
}