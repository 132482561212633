.chat {
	display: flex;
	flex-direction: column;
}

.chat .chat-message-list {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 5px 10px;
}

.chat .chat-message-list .chat-message-item {
	display: flex;
	flex-direction: column;
	background-color: #0e77ff;
	color: #ffffff;
	margin: 5px 0;
	font-size: 14px;
	max-width: 80%;
	padding: 10px;
	border-radius: 15px 15px 15px 0;
}

.chat .chat-message-list .chat-message-item.from-me {
	align-self: flex-end;
	border-radius: 15px 15px 0 15px;
	background-color: #0bb30b;
}

.chat .chat-message-list .message-header {
	display: flex;
	justify-content: space-between;
}

.chat .chat-message-list .message-header .message-author {
	font-weight: 700;
	margin-right: 15px;
}

.chat .chat-message-form {
	border-top: 1px solid rgba(0,0,0,0.15);
}

.chat .chat-message-form form {
	display: flex;
	height: 40px;
	line-height: 40px;
}

.chat .chat-message-form input {
	flex: 1;
	border: 0;
	outline: 0;
	font-size: 16px;
	font-family: inherit;
	padding: 0 10px;
	background-color: transparent;
}

.chat .chat-message-form button {
	border: 0;
	outline: 0;
	background-color: transparent;
	cursor: pointer;
}