.event-popup {
	transition: opacity .3s, transform .3s, visibility 0s .3s;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	right: calc(100% + 15px);
	top: -50px;
	background-color: #ffffff;
	box-shadow: 0 0 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	border-radius: 5px;
	width: 200px;
	color: #2f2f2f;
	transform-origin: right center;
	transform: rotateY(-30deg);
	z-index: 1;
}

.event-popup.right-popup {
	right: unset;
	left: calc(100% + 15px);
	transform: rotateY(30deg);
	transform-origin: left center;
}

.event-item.is-selected .event-popup {
	transition: opacity .2s, transform .3s, visibility 0s 0s;
	visibility: visible;
	opacity: 1;
	transform: rotateY(0);
}

.event-popup::after {
	content: "";
	position: absolute;
	left: 100%;
	top: 50px;
	width: 0;
	height: 0;
	border-left: 15px solid #fff;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
}

.event-popup.right-popup::after {
	left: unset;
	right: 100%;
	border-left: 15px solid transparent;
	border-right: 15px solid #fff;
}

.event-popup-title {
	padding: 10px 0;
	text-align: center;
	border-bottom: 1px solid #edecec;
	color: #494949;
	font-weight: 600;
	font-size: 18px;
}

.event-popup-desc {
	border-top: 1px solid #edecec;
}

.event-popup-content > div {
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
}

.event-popup-content > div.center {
	justify-content: center;
}

.event-popup-desc {
	padding: 5px 10px;
	text-align: justify;
}

.calendar-container {
	background-color: #fff;
}

.calendar-container > div {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
}

.calendar-header > div {
	font-weight: 600;
	text-align: right;
	padding: 15px 10px;
	color: #686868;
	border: 1px solid #edecec;
}

.calendar-day {
	border: 1px solid #edecec;
	min-height: 120px;
}

.calendar-day .day-number {
	font-weight: 600;
	text-align: right;
	padding: 5px 10px;
	color: #686868;
}

.calendar-day.not-current-month .day-number {
	color: #acacac;
}

.calendar-day .event-list {
	padding: 0 5px;
}

.calendar-container .event-item {
	position: relative;
}

.calendar-container .event-item .event-box {
	transition: background-position .3s;
	cursor: pointer;
	display: flex;
	border-radius: 5px;
	padding: 5px 10px;
	background-color: #0093E9;
	color: #fff;
	background-size: 300px 50px !important;
	margin-bottom: 5px;
}

.calendar-container .event-item.gradient-1 .event-box {
	background: linear-gradient(115deg, #FAD961 0, #F76B1C 120px);
}
/*
.calendar-container .event-item.gradient-1 {
	background-color: #FAD961;
	background-image: linear-gradient(90deg, #FAD961 0%, #F76B1C 100%);
	color: #ffffff;
} */

.calendar-container .event-item.gradient-2 .event-box {
	background-color: #0093E9;
	background-image: linear-gradient(160deg, #0093E9 0, #80D0C7 100px);
	color: #fff;
}

.calendar-container .event-item.gradient-3 .event-box { /* -- */
	background-color: #4158D0;
	background-image: linear-gradient(43deg, #384eb9, #f05de6 100px);
	color: #fff;
}

.calendar-container .event-item.gradient-4 .event-box {
	background-color: #21D4FD;
	background-image: linear-gradient(19deg, #21D4FD 0, #B721FF 100px);
	color: #ffffff;
}

.calendar-container .event-item.gradient-5 .event-box {
	background-color: #FFE53B;
	background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);

	color: #ffffff;
}

.calendar-container .event-item.gradient-6 .event-box {
	background-color: #FBDA61;
	background-image: linear-gradient(45deg, #FBDA61 0, #FF5ACD 100px);

	color: #ffffff;
}

.calendar-container .event-item .event-box:hover {
	background-position: -100px 0;
}
