.status-page .status-container {
	border: 2px solid #bbbbbb;
	background-color: #dddddd;
	border-radius: 3px;
	padding: 15px 30px;
	margin: 15px 0;
	font-size: 20px;
}

.status-page .status-container.online {
	border: 2px solid #09f309;
	background-color: #76ff76;
}

.status-page .status-container.offline {
	border: 2px solid #e41212;
	background-color: #ff5151;
}