.coalition-display.coalition-blue, .coalition-display.coalition-2 {
	background-color: #0e77ff;
}

.coalition-display.coalition-red, .coalition-display.coalition-1 {
	background-color: #FF0000;
}

.coalition-display.coalition-neutral, .coalition-display.coalition-spectator, .coalition-display.coalition-0 {
	background-color: #dddddd;
	color: #000000;
}