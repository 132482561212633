@media all and (max-width: 700px) {

	.player-team-kills-page h1.section-title {
		font-size: 30px;
	}

}

@media all and (max-width: 500px) {

	.player-team-kills-page h1.section-title {
		font-size: 25px;
	}
}