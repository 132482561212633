.files-list-container {
	margin-top: 30px;
}

.files-list-container .files-header {
	font-weight: 600;
	color: #333;
}

.files-list-container .files-header > div, .files-list-container .files-list .file-item {
	display: grid;
	grid-template-columns: 1fr 150px 200px;
	border-radius: 5px;
	padding: 10px;
}

.files-list-container .files-list .file-item:nth-child(1) {
	margin-top: 0;
}

.files-list-container .file-name {
	text-align: left;
}

.files-list-container .file-item {
	text-decoration: none;
	color: inherit;
	outline: 0;
	transition: all .2s;
	display: flex;
	align-items: center;
	margin: 5px 0;
	border: 1px solid transparent;
}

.file-item:hover {
	border-color: #0e77ff;
}

.files-list-container .files-list .file-name {
	color: #0e77ff;
}