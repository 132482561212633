.admin-logs-page .sidebar-container-content {
	max-width: calc(100% - 300px); /*  To prevent sidebar content to overflow sidebar container  */
}

.logs-box {
	overflow: auto;
	max-width: 100%;
	white-space: pre;
	border: 1px solid #c5c5c5;
	padding: 10px 20px;
	border-radius: 5px;
	background-color: #fff;
}