.dark-theme-switch-container {
	display: flex;
	align-items: center;
	position: absolute;
	right: 15px;
	top: 15px;
}

.dark-theme-switch {
	position: relative;
	display: flex;
	justify-content: space-between;
	background-color: #ccc;
	height: 30px;
	line-height: 30px;
	border-radius: 15px;
	width: 60px;
	padding: 2px;
	cursor: pointer;
}

.dark-theme .dark-theme-switch {
	background-color: #232627;
}

.dark-theme-switch .switch-dot {
	transition: left .1s;
	position: absolute;
	left: 4px;
	top: 4px;
	height: 22px;
	border-radius: 50%;
	width: 24px;
	background-color: #fff;
}

.dark-theme .dark-theme-switch .switch-dot {
	left: calc(100% - 28px);
}

.dark-theme-switch .icon {
	display: flex;
	align-items: center;
	margin: 0 2px;
}

.dark-theme header.header .hamburger-icon span {
	background-color: #e8e6e3;
}

.theme-switch-icon {
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

@media all and (max-width: 1000px) {
	.dark-theme-switch-container {
		right: 100px;
	}
}