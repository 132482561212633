.screenshots-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 15px;
	margin: 15px -5px;
}

.screenshot-item {
	display: flex;
	background-position: center;
	background-size: cover;
}

.screenshot-item.is-selected img {
	border-color: #0e77ff;
}

.screenshot-item img {
	cursor: pointer;
	width: 100%;
	border-radius: 10px;
	border: 5px solid transparent;
}