header.header {
	transition: color .3s, background-color .3s, box-shadow .3s;
	position: fixed;
	top: 0;
	left: 0; right: 0;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	line-height: 60px;
	background-color: #ffffff;
	box-shadow: 0 1px 1px rgba(0,0,0,0.15);
	color: #222222;
}

header.header-transparent {
	color: #ffffff;
	background-color: transparent;
	box-shadow: none;
}

header.header nav.nav {
	display: flex;
	position: relative;
	z-index: 2;
}

header.header > a, header.header nav.nav > a, header.header nav.nav .dropdown-title, header.header .dropdown-items > div > * {
	transition: background-color .2s;
	cursor: pointer;
	text-decoration: none;
	height: 60px;
	padding: 0 10px;
	word-wrap: none;
	white-space: nowrap;
	color: inherit;
	text-align: center;
}

header.header .dropdown-title {
	width: 100%;
}

header.header nav.nav > .header-hover:hover, header.header .dropdown-items > div > *:hover {
	background-color: #eceaea;
}

header.header-transparent nav.nav > .header-hover:hover {
	background-color: transparent;
}

header.header-transparent > a, header.header-transparent nav.nav > * {
	color: #ffffff;
}

header.header .logo {
	transition: color .3s;
	position: relative;
	z-index: 5;
	font-size: 22px;
	padding: 0;
	font-weight: 700;
	color: #0e77ff;
	padding: 5px 0;
	display: flex;
	align-items: center;
}

header.header-transparent .logo {
	color: #ffffff;
}

header.header .logo img {
	margin-right: 10px;
	height: 100%;
	background-color: #000000;
	border-radius: 50%;
}

header.header .dropdown-menu {
	transition: color .3s;
	position: relative;
	display: flex;
	justify-content: center;
}

.dropdown-animate-items {
	position: absolute;
	top: calc(100% + 15px);
	left: 0;
	right: 0;
}

header.header .dropdown-items {
	transition: transform .3s, opacity .3s, visibility 0s .3s;
	position: absolute;
	min-width: 150px;
	top: 100%;
	padding-top: 10px;
	filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, .5));
	color: #222222;

	visibility: hidden;
	transform-origin: top center;
	transform: perspective(300px) rotateX(-30deg);
	opacity: 0;
}

.dropdown-menu.is-opened .dropdown-items {
	transition: transform .3s, opacity .3s, visibility 0s 0s;
	visibility: visible;
	transform: rotate3d(1, 1, 0, 0);
	opacity: 1;
}

.dropdown-items > div > *:first-child::after {
	transition: border-bottom .2s;
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: block;
	border-bottom: 10px solid #ffffff;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
}

.dropdown-items > div > *:first-child:hover::after {
	border-bottom: 10px solid #eceaea;
}

header.header .dropdown-items > div {
	text-align: center;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	overflow: hidden;
}

header.header .dropdown-items a {
	text-decoration: none;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	color: inherit;
}

header.header .dropdown-items a:last-child {
	border-bottom: 0;
}

header.header .hamburger-icon {
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 70px;
	padding: 15px;
	cursor: pointer;
}

header.header .hamburger-icon span {
	transition: background-color .3s;
	height: 5px;
	background-color: #000000;
	width: 100%;
	border-radius: 5px;
}

header.header-transparent .hamburger-icon span {
	background-color: #ffffff !important;
}

header.header .menu-overlay {
	display: none;
	position: fixed;
	top: 0; bottom: 0;
	left: 0; right: 0;
	background-color: #000000;
	opacity: 0;
	visibility: hidden;
	cursor: pointer;
	z-index: 1;
}

@media all and (min-width: 1000px) and (max-width: 1350px) {
	nav.nav {
		margin-right: 60px;
	}
}

@media all and (max-width: 1000px) {

	/* Set hamburger menu */
	header.header .hamburger-icon {
		display: flex;
	}

	header.is-opened .logo {
		color: #0e77ff;
	}

	header.header nav.nav {
		transition: transform .3s;
		padding-top: 60px;
		position: fixed;
		left: -80%;
		top: 0; bottom: 0;
		background-color: #ffffff;
		color: #000000;
		width: 80%;
		display: flex;
		flex-direction: column;
		transform: translateX(0);
	}

	header.header nav.nav > * {
		border-bottom: 1px solid rgba(0,0,0,0.15);
	}

	header.header nav.nav > *:first-child {
		border-top: 1px solid rgba(0,0,0,0.15);
	}

	header.header-transparent > a, header.header-transparent nav.nav > * {
		color: #000000;
	}

	header.header nav.nav .server-status {
		justify-content: center;
	}

	header.header.is-opened nav.nav {
		transform: translateX(100%);
	}

	header.header .menu-overlay {
		transition: visibility 0s .3s, opacity .3s;
		display: block;
	}

	header.header.is-opened .menu-overlay {
		transition: visibility 0s 0s, opacity .3s;
		visibility: visible;
		opacity: 0.8;
	}

}