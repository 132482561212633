footer.footer {
	max-height: 60px;
	overflow: hidden;
	line-height: 60px;
	background-color: #022d64;
	color: #ffffff;
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(237, 236, 236, .2);
}

footer.footer a {
	color: inherit;
}

footer.footer .left a {
	margin-right: 20px;
}

footer.footer .right a {
	margin-left: 20px;
}