.player-planes-page .plane-stats .plane-stats-inline {
	display: flex;
	justify-content: space-around;
}

.player-planes-page .stat-box {
	font-size: 24px;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.player-planes-page .stat-box .stat-label {
	font-size: 18px;
}

.player-planes-page .plane-stats:last-child {
	margin-bottom: 0;
}

.player-planes-page .planes-list {
	padding-bottom: 20px;
}

.player-planes-page .weapon-stats .stat-box .stat-number {
	font-size: 22px;
}

.player-planes-page .weapon-stats .stat-box .stat-label {
	font-size: 18px;
}

@media all and (max-width: 700px) {

	.player-planes-page h1.section-title {
		font-size: 30px;
	}

	.player-planes-page .stat-box {
		font-size: 20px;
	}

	.player-planes-page .stat-box .stat-label {
		font-size: 16px;
	}

	.player-planes-page .weapon-stats .stat-box .stat-number {
		font-size: 20px;
	}

	.player-planes-page .weapon-stats .stat-box .stat-label {
		font-size: 16px;
	}

}

@media all and (max-width: 500px) {

	.player-planes-page h1.section-title {
		font-size: 25px;
	}
}