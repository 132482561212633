.stats-page .parallax-banner {
	height: 100vh;
	background-color: #dddddd;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
}

.stats-page .parallax-banner > div {
	height: 100vh;
	width: 100%;
}

.stats-page .parallax-banner .background {
	transition: all .3s; /* saturate(1.2)   brightness(.8) */
	filter: sepia(.2);
	width: 110%;
	height: 110%;
	background-size: cover;
	position: relative;
	top: -5%;
	left: -5%;
}

.stats-page #plane-1 {
	width: 105%;
	height: 105%;
	background-position: 90% 0%;
	top: -130px;
	left: -5%;
}

.stats-page #plane-2 {
	width: 120%;
	height: 120%;
	top: -20px;
	background-position: 40% 75%;
	left: -5%;
}

.stats-page .stats-bar {
	position: absolute;
	bottom: 0;
	left: 0; right: 0;
	z-index: 2;
	background-color: #ffffff;
	display: flex;
	height: 120px;
	align-items: center;
}

.stats-page .stat-item {
	/*-webkit-box-shadow: 10px 10px 116px -25px rgba(2, 1, 1, 0.75);
	-moz-box-shadow: 10px 10px 116px -25px rgba(0,0,0,0.75);
	box-shadow: 10px 10px 116px -25px rgba(0,0,0,0.75); */
	text-align: center;
	flex: 1;
	display: flex;
	flex-direction: column;
	font-size: 30px;
}

.stats-page .stat-item .stat-label {
	margin-top: 10px;
	font-size: 20px;
}