#gallery-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
	padding-bottom: 500px;
}

.gallery-item {
	transition: max-height .3s;
	cursor: pointer;
	position: relative;
	max-height: 200px;
}

.gallery-item.is-opened {
	max-height: 1000px;
	z-index: 5;
}

.gallery-item > div {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	transition: max-width .3s, margin-left .3s 30ms;
	width: calc(400% + 30px);
	max-width: 100%;
	margin-left: 0;
}

.gallery-item.is-opened > div {
	transition: max-width .3s, margin-left .2s;
	max-width: 500%;
	margin-left: 0;
}

.gallery-item:nth-child(4n - 2).is-opened > div {
	margin-left: calc(-100% - 10px);
}

.gallery-item:nth-child(4n - 1).is-opened > div {
	margin-left: calc(-200% - 20px);
}

.gallery-item:nth-child(4n).is-opened > div {
	margin-left: calc(-300% - 30px);
}

.gallery-item div {
	height: 100%;
}

.gallery-item .background {
	transition: transform .3s;
	background-size: cover;
	background-position: center;
}

.gallery-item.is-hovered .background {
	transform: scale(1.1);
}

.gallery-item img {
	width: 100%;
}

.gallery-item.row-2 {
	max-height: unset;
	grid-row: span 2;
}

.gallery-item.column-2 {
	max-height: unset;
	grid-column: span 2;
}

.gallery-item.square {
	grid-column: span 2;
	grid-row: span 2;
	max-height: unset;
}

.gallery-item .author {
	transition: bottom .3s;
	position: absolute;
	right: 15px;
	bottom: 15px;
	font-size: 18px;
	z-index: 1;
	color: #fff;
	font-weight: 700;
	letter-spacing: 1px;
}
