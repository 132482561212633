:root {
	--container-width: calc(100% - 40px);
	--sidebar-container-width: calc(100% - 40px);
	/* color: rgb(53, 148, 247); */
}

html {
	scroll-behavior: smooth !important;
}

* {
	box-sizing: border-box;
}

#root > div.container {
	min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-padding {
	padding-top: 60px;
}

.cards-container {
	display: flex;
	gap: 20px;
	margin: 5px 0;
}

.cards-container .card {
	margin: 0;
}

.card {
	background-color: #ffffff;
	/*box-shadow: 0 1px 1px rgba(0,0,0,0.15); */
	border: 1px solid rgba(0,0,0,0.15);
	margin: 20px 0;
}

.card .card-title {
	padding: 5px 10px;
	font-size: 22px;
	color: #444444;
	border-bottom: 1px solid rgba(0,0,0,0.15);
}

.card .card-body {
	padding: 5px 10px;
}

.card.no-padding .card-body {
	padding: 0;
}

.flex {
	display: flex;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.flex-4 {
	flex: 4;
}

.flex-5 {
	flex: 5;
}

.flex-column {
	flex-direction: column;
}

.flex-end {
	justify-content: flex-end;
}

.flex-center {
    justify-content: center;
}

.space-around {
	justify-content: space-around;
}

.pointer {
	cursor: pointer;
}

.mb-20 {
    margin-bottom: 20px;
}

.container {
	width: 100%;
	padding-left: calc(50% - var(--container-width) / 2);
	padding-right: calc(50% - var(--container-width) / 2);
}

.left {
	text-align: left !important;
}

.content {
	max-width: 825px;
	margin: auto;
}

.sidebar-container {
	display: flex;
}

.sidebar-container-content {
	flex: 1;
	width: 100%;
	padding-left: calc(50% - var(--sidebar-container-width) / 2);
	padding-right: calc(50% - var(--sidebar-container-width) / 2);
}

.background-grey {
	background-color: #edecec;
}

.black-color {
	color: #000000;
}

.annotation {
	border-radius: 10px;
	display: inline-block;
	padding: 0 15px;
	height: 25px;
	line-height: 25px;
	background-color: #edecec;
	color: #fff;
}

.center {
	justify-content: center;
	text-align: center;
}

.justify {
	text-align: justify;
}

.section-title {
	display: flex;
	font-weight: 700;
	color: #0e77ff;
}

h1.section-title {
	font-size: 40px;
	margin-bottom: 30px;
	margin-top: 30px;
}

h2.section-title {
	font-size: 25px;
	margin-bottom: 20px;
	margin-top: 20px;
}

.section-title .title {
	flex: 1;
}

.section-title a.title {
	text-decoration: none;
}

.title-2 {
	text-align: center;
	font-size: 30px;
	color: #0e77ff;
	font-weight: 700;
	margin-bottom: 30px;
}

.section-text {
	font-size: 20px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 100;
	line-height: 30px;
}

.section-text a {
	color: inherit;
}

.section-title .pre-icon, .section-title .post-icon {
	width: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	color: inherit;
}

.section-title .pre-icon:hover, .section-title .post-icon:hover {
	background-color: #dddddd;
}

.section-subtitle {
	display: flex;
	font-weight: 700;
	color: #0e77ff;
	font-size: 30px;
	margin: 20px 0;
}

.inputs-row {
	display: flex;
	gap: 20px;
}

.btn-group {
	display: flex;
}

.btn-group .btn {
	border-radius: 0;
}

.btn-group .btn:nth-child(1) {
	border-radius: 5px 0 0 5px;
}

.btn-group .btn:last-child {
	border-radius: 0 5px 5px 0;
}

.btn {
	transition: all .2s;
	cursor: pointer;
	background-color: #0e77ff;
	color: #ffffff;
	border: 0;
	padding: 10px 15px;
	font-size: 18px;
	border-radius: 5px;
	text-decoration: none;
}

.btn-small {
	padding: 8px 12px;
	font-size: 16px;
}

.btn:hover {
	background-color: #0d66db;
}

.btn-success {
	background-color: #28a745;
}

.btn-success:hover {
	background-color: #228a3a;
}

.btn-warning {
	background-color: #f07809;
}

.btn-warning:hover {
	background-color: #d46b09;
}

.btn-danger {
	background-color: #dc3545;
}

.btn-danger:hover {
	background-color: #be2b3a;
}

.subtitle {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
}

.icon {
    transition: .2s;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
}

.icon:hover {
    background-color: #ececec;
}

.icon svg path {
    stroke: currentColor !important;
}

.list {
	display: flex;
	flex-direction: column;
}

.list .list-item, .list .list-header {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
	min-height: 45px;
	/*line-height: 45px; */
	text-decoration: none;
	color: inherit;
}

.list .list-item *, .list .list-header * {
	word-break: keep-all;
	white-space: nowrap;
}

.list .list-header {
	color: #444444;
	border-bottom: 1px solid rgba(0,0,0,0.15);
}

.list .list-item:hover {
	background-color: #eceaea;
}

.list .list-item .left, .list .list-header .left {
	min-width: 0;
	display: flex;
	z-index: 1;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
}

.list .list-item .middle, .list .list-header .middle {
	min-width: 0;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.list .list-item .right, .list .list-header .right {
	min-width: 0;
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
}

.table {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #ffffff;
}

.table thead th {
	background-color: #0e77ff;
	color: #ffffff;
	padding: 15px 0;
}

.table tbody tr {
	background-color: #ffffff;
}

.table tbody tr:nth-child(even) {
	background-color: #f0f0f0;
}

.table tbody tr:hover {
	background-color: #eceaea;
}

.table tbody td {
	min-width: 15px;
	text-align: center;
	padding: 15px 0;
}

.table tbody td.small, .table tbody th.small {
	width: 50px;
	padding: 0;
}

.table tbody td.table-actions, .table tbody th.table-actions {
	display: flex;
	padding-right: 15px;
	padding-left: 15px;
}

.table .nostretch {
    width: 1%;
    white-space: nowrap;
}

@media all and (min-width: 1240px)
{
	:root {
		--container-width: 1200px;
	}
}

@media all and (min-width: 1440px)
{
	:root {
		--sidebar-container-width: 1400px;
	}
}