.player-page .cards-container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-areas: "stats planes"
						 "names team-kills";
}

.player-page .cards-container .stats-card {
	grid-area: stats;
}

.player-page .cards-container .names-card {
	grid-area: names;
}

.player-page .cards-container .planes-card {
	grid-area: planes;
}

.player-page .cards-container .team-kills-card {
	grid-area: team-kills;
}

@media all and (max-width: 700px) {

	.player-page h1.section-title {
		font-size: 30px;
	}

	.player-page .cards-container {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas: "stats"
							 "planes"
							 "team-kills"
							 "names";
	}
}

@media all and (max-width: 500px) {

	.player-page h1.section-title {
		font-size: 25px;
	}
}