.server-status {
	font-size: 16px !important;
	display: flex !important;
	align-items: center !important;
	color: inherit;
	text-decoration: none;
}

.server-status > div {
	display: inline-block;
}

.server-status .status-value {
	background-color: rgba(0, 0, 0, .8);
	color: #ffffff;
	border-radius: 15px;
	padding: 5px 10px;
	margin-left: 5px;
	height: 30px;
	display: flex;
	align-items: center;
}

header.header-transparent {
	background-color: rgba(0, 0, 0, .5);
}

.server-status .status-value:before {
	display: block;
	content: '';
	background-color: #EDEDED;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	margin-right: 5px;
}

.server-status.status-online .status-value:before {
	background-color: #00FF00;
}

.server-status.status-offline .status-value:before {
	background-color: #FF0000;
}

@media all and (max-width: 750px) {
	.server-status .status-label {
		/*  display: none;*/
	}
}